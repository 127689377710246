import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import { FaPhone, FaWhatsapp, FaAccessibleIcon, FaPlus, FaMinus, FaUndo, FaTextHeight, FaAlignLeft, FaTextWidth, FaTint } from 'react-icons/fa'; // נוסיף את FaTint עבור רוויה
import styled, { createGlobalStyle } from 'styled-components';

function App() {
  const [fontSize, setFontSize] = useState(16); // ברירת מחדל גודל טקסט
  const [isMenuOpen, setIsMenuOpen] = useState(false); // מצב פתיחת תפריט נגישות
  const [letterSpacing, setLetterSpacing] = useState(0); // ברירת מחדל לרווח אותיות
  const [lineHeight, setLineHeight] = useState(''); // ברירת מחדל לגובה שורה
  const [textAlign, setTextAlign] = useState(''); // ברירת מחדל ליישור טקסט לימין
  const [saturation, setSaturation] = useState('1'); // רוויה (saturation) ברירת מחדל - 1 זה רגיל

  // פונקציות נגישות להגדלה והקטנה של גודל הטקסט
  const increaseFontSize = () => setFontSize((prev) => prev + 2);
  const decreaseFontSize = () => setFontSize((prev) => (prev > 12 ? prev - 2 : prev)); // הגבלת הגודל המינימלי ל-12
  const resetSettings = () => {
    setFontSize(16); // מאפס את גודל הטקסט לברירת מחדל (16px)
    setLetterSpacing(0); // מאפס ריווח טקסט
    setLineHeight(1.5); // מאפס גובה שורה
    setTextAlign('right'); // מאפס יישור טקסט לימין
    setSaturation(1); // מאפס רוויה
  };

  // פונקציות נגישות נוספות
  const increaseLetterSpacing = () => setLetterSpacing((prev) => prev + 1); // הגדלת ריווח בין אותיות
  const increaseLineHeight = () => setLineHeight((prev) => prev + 0.2); // הגדלת גובה השורה
  const toggleTextAlign = () => setTextAlign((prev) => (prev === 'right' ? 'left' : 'right')); // שינוי יישור הטקסט
  const increaseSaturation = () => setSaturation((prev) => Math.min(prev + 0.2, 2)); // הגדלת רוויה
  const decreaseSaturation = () => setSaturation((prev) => Math.max(prev - 0.2, 0)); // הקטנת רוויה

  const toggleMenu = () => setIsMenuOpen((prev) => !prev); // פתיחה/סגירה של תפריט הנגישות

  return (
    <Router>
      <GlobalStyle
        fontSize={fontSize}
        letterSpacing={letterSpacing}
        lineHeight={lineHeight}
        textAlign={textAlign}
        saturation={saturation}
      />
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />

        {/* אייקונים קבועים שמוצגים בכל דף */}
        <div style={fixedIconsStyle}>
          {/* בועה לטלפון */}
          <div style={phoneIconStyle}>
            <a href="tel:0543038671" style={{ color: 'inherit' }}>
              <FaPhone />
            </a>
          </div>

          {/* בועה לווטסאפ */}
          <div style={whatsappIconStyle}>
            <a href="https://wa.me/0543038671" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
              <FaWhatsapp />
            </a>
          </div>

          {/* אייקון לפתיחת תפריט נגישות */}
          <div style={iconWrapperStyle}>
            <button style={iconButtonStyle} onClick={toggleMenu} title="Accessibility Options">
              <FaAccessibleIcon />
            </button>
            </div>
            {/* תפריט נגישות שנפתח בלחיצה על האייקון */}
            {isMenuOpen && (
              <Modal>
                <div style={accessibilityMenuStyle}>
                  <button style={closeButtonStyle} onClick={toggleMenu} title="Close Menu">
                    ✕
                  </button>
                  <h3>אפשרויות נגישות</h3>
                  <button style={menuButtonStyle} onClick={increaseFontSize} title="Increase Font Size">
                    <FaPlus /> הגדל טקסט
                  </button>
                  <button style={menuButtonStyle} onClick={decreaseFontSize} title="Decrease Font Size">
                    <FaMinus /> הקטן טקסט
                  </button>

                  <button style={menuButtonStyle} onClick={increaseLetterSpacing} title="Increase Letter Spacing">
                    <FaTextWidth /> ריווח טקסט
                  </button>
                  <button style={menuButtonStyle} onClick={increaseLineHeight} title="Increase Line Height">
                    <FaTextHeight /> גובה שורה
                  </button>
                  <button style={menuButtonStyle} onClick={toggleTextAlign} title="Toggle Text Align">
                    <FaAlignLeft /> יישור טקסט
                  </button>
                  <button style={menuButtonStyle} onClick={increaseSaturation} title="Increase Saturation">
                    <FaTint /> הגדל רוויה
                  </button>
                  <button style={menuButtonStyle} onClick={decreaseSaturation} title="Decrease Saturation">
                    <FaTint /> הקטן רוויה
                  </button>
                <button style={menuButtonStyle} onClick={resetSettings} title="Reset to Default">
                  <FaUndo /> איפוס לברירת מחדל
                </button>
                </div>
              </Modal>
            )}
          </div>
        </div>
    </Router>
  );
}

export default App;

// סגנון גלובלי שיחיל את כל ההגדרות הנוספות
const GlobalStyle = createGlobalStyle`


  h1, h2, h3, h4, h5, h6, p, li, html {
    font-size: ${(props) => props.fontSize}px;
    letter-spacing: ${(props) => props.letterSpacing}px;
    line-height: ${(props) => props.lineHeight};
    text-align: ${(props) => props.textAlign};
    filter: saturate(${(props) => props.saturation});
    overflow-x: hidden;
  }
`;


const fixedIconsStyle = {
  position: 'sticky', // האייקונים יהיו סטיקי ו"ילכו" יחד עם הגלילה
  bottom: '30px',
  display: 'flex',   // מוודא שהאייקונים מסודרים
  flexDirection: 'column', // אייקונים אחד מעל השני
  gap: '10px',       // ריווח בין האייקונים
  zIndex: 1000, // לוודא שהאייקונים תמיד ייראו מעל התוכן
  direction: 'rtl',
};

// סגנון לכפתור ה-X (סגירת המודאל)
const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  left: '10px',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '12px',
  color: '#ffffff',
  cursor: 'pointer',
};

const iconWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '60px',  // גודל הבועה (עיגול)
  height: '60px', // גודל הבועה (עיגול)
  borderRadius: '50%', // עיגול מושלם
  cursor: 'pointer',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)', // הצללה קלה לאייקונים
};

// סגנון לווטסאפ עם בועה ירוקה ואייקון לבן
const whatsappIconStyle = {
  ...iconWrapperStyle,
  backgroundColor: '#25D366', // צבע ירוק לבועה
  color: '#fff',  // צבע לבן לאייקון
};

// סגנון לטלפון עם בועה כחולה ואייקון לבן
const phoneIconStyle = {
  ...iconWrapperStyle,
  backgroundColor: '#007BFF', // צבע כחול לבועה
  color: '#fff',  // צבע לבן לאייקון
};


const iconButtonStyle = {
  ...iconWrapperStyle,
backgroundColor: '#fff', // ��בע ����ו�� לבו��ה
color: '#213ceb', // ��בע לבו לאיי��ו��
};

// סגנון לתפריט הנגישות (מודאל) בצבע כחול כהה
const accessibilityMenuStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '20px',
  backgroundColor: '#1a237e', // כחול כהה
  color: '#fff',
  borderRadius: '8px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
  width: '250px', // תפריט קצת קטן יותר
  zIndex: 9999,
};

// סגנון לכפתורי התפריט
const menuButtonStyle = {
  backgroundColor: '#3949ab',
  border: '1px solid #ddd',
  padding: '8px',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#fff',
};

// מודאל (כמו תפריט נפתח) צמוד לאייקון הנגישות
const Modal = styled.div`
  position: absolute;
  top: -300px; /* מיקום מעל האייקון */
  right: 0;
  z-index: 9999;
`;
